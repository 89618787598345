import { React, useState, useEffect } from "react";
import ReactFullpage from "@fullpage/react-fullpage";

import { BiChevronDown } from "react-icons/bi";

import { REST_API_KEY, REDIRECT_URI } from "./LoginData";
import isLogin from "./Login.js";

import logo from "../asset/main/logo.png";
import instagram from "../asset/main/instagram.png";
import kakao from "../asset/main/kakao.png";
import rushclan from "../asset/main/rushclan.png";
import loginbtn from "../asset/login/kakao_login.png";

function Main() {
  const [login, setLogin] = useState(false);
  const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code`;
  const handleLogin = () => {
    window.location.href = KAKAO_AUTH_URL;
  };

  const [isModalOn, setIsModalOn] = useState(false);
  const [pnum, setPnum] = useState("");

  const showModal = (
    <div
      className="div-modal-background"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.0)" }}
    >
      <div className="div-modal">
        <div
          className="div-samename-button-section"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="div-kakaologin-register-wrapper">
            <div className="div-login-input-pnum">
              휴대전화번호를 입력해주세요.
              <input
                maxLength="11"
                onChange={(e) => setPnum(e.target.value)}
                value={pnum}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: 8,
              justifyContent: "center",
              marginTop: 10,
            }}
          >
            <button
              className="div-samename-button-cancel"
              onClick={() => setIsModalOn(false)}
            >
              취소
            </button>
            <button
              className="div-samename-button-check"
              onClick={() => handleLoginTmp()}
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  const handleLoginTmp = () => {
    if (
      // 2024.11
      pnum === "01093653545" ||
      pnum === "01055327685" ||
      pnum === "01066306044" ||
      pnum === "01031369209" ||
      pnum === "01090081871" ||
      pnum === "01082301575" ||
      pnum === "01058252283" ||
      pnum === "01075729529" ||
      // 2024.12
      pnum === "01025496076" ||
      pnum === "01098897082" ||
      pnum === "01050440037" ||
      pnum === "01095346644" ||
      pnum === "01063301311" ||
      pnum === "01023788700" ||
      pnum === "01096646989"
    ) {
      window.localStorage.setItem("id", pnum);
      window.localStorage.setItem(
        "imageUrl",
        "https://teammagnus.net/static/media/logo.3118256a6e9455c67c54.png"
      );
      window.location.href = "/profile";
    } else {
      window.alert(
        "휴대전화번호를 다시 확인해주세요.\n*본 기능은 신입 회원만 이용 가능합니다.(카카오 로그인이 안 될 경우에만 사용)"
      );
    }
  };

  useEffect(() => {
    console.log(isLogin());
  }, []);

  return (
    <>
      <ReactFullpage
        scrollOverflow={true}
        render={({ fullpageApi }) => (
          <div id="fullpage-wrapper">
            <div className="section">
              <div className="div-main-section-01">
                {isModalOn && showModal}
                {!isModalOn && <img className="img-main" src={logo} alt="" />}
                {!isModalOn && (
                  <>
                    {!isLogin() && (
                      <img
                        className="img-main"
                        style={{ marginTop: "50px" }}
                        src={loginbtn}
                        alt=""
                        onClick={handleLogin}
                      />
                    )}

                    {!isLogin() && (
                      <div
                        className="img-main"
                        style={{
                          marginTop: "10px",
                          width: "270px",
                          height: "40.5px",
                          backgroundColor: "rgba(0, 0, 0, 0.2)",
                          borderRadius: "5px",
                          color: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12.5px",
                          fontWeight: "bold",
                        }}
                        alt=""
                        onClick={() => setIsModalOn(true)}
                      >
                        임시 로그인
                      </div>
                    )}
                  </>
                )}
              </div>
              <div onClick={() => fullpageApi.moveSectionDown()}>
                <BiChevronDown className="icon-main-arrow-down1" size="20" />
                <BiChevronDown className="icon-main-arrow-down2" size="20" />
              </div>
            </div>
            <div className="section">
              <div className="div-main-section-01-01-01">
                {/* <div className="div-main-section-01-01-01-schedule">
                  <div className="div-main-section-01-01-01-left">금</div>
                  <div className="div-main-section-01-01-01-right">
                    20:00~21:30
                  </div>
                </div> */}
                <div className="div-main-section-01-01-01-schedule">
                  <div className="div-main-section-01-01-01-left">토</div>
                  <div className="div-main-section-01-01-01-right">
                    14:00~16:00
                  </div>
                </div>
                <div className="div-main-section-01-01-01-schedule">
                  <div className="div-main-section-01-01-01-left">일</div>
                  <div className="div-main-section-01-01-01-right">
                    16:00~18:00
                  </div>
                </div>
              </div>
              <br />
              <div className="div-main-section-01-01-02">
                충무로 러쉬클랜에서
                <br /> 훈련을 진행합니다.
              </div>
              <div className="div-main-rushclan">
                <img
                  className="img-main-rushclan"
                  src={rushclan}
                  alt=""
                  onClick={() =>
                    (window.location.href = "https://naver.me/FXZPKnCR")
                  }
                />
              </div>

              <div onClick={() => fullpageApi.moveSectionDown()}>
                <BiChevronDown className="icon-main-arrow-down1" size="20" />
                <BiChevronDown className="icon-main-arrow-down2" size="20" />
              </div>
            </div>
            <div className="section">
              <div className="div-main-section-01-02">
                기존 부원 뿐만 아니라
                <br /> 신입부원들도 함께 운동을 하고 있습니다.
                <br />
                <br />
                블루벨트 이상의 코치진이 훈련을 진행하고 있습니다. <br />
                <br />
                추가로 3층에 있는 헬스장을
                <br />
                무료로 마음껏 이용하실 수 있습니다.
                <br />
                <br />
                훈련 후 재미있는 뒷풀이가 종종 있습니다!
              </div>
              <div onClick={() => fullpageApi.moveSectionDown()}>
                <BiChevronDown className="icon-main-arrow-down1" size="20" />
                <BiChevronDown className="icon-main-arrow-down2" size="20" />
              </div>
            </div>
            <div className="section">
              <div className="div-main-section-01-03">
                마그누스 동아리원 혜택
              </div>
              <div className="div-main-section-01-02">
                <br />
                매 분기 주짓수 시합 출전비 반액 지원 <br />
                빠지, 스키장 등 함께 놀러가는 동아리 mt <br />
                주말 주짓수 이외에도 헬스장 무제한 사용가능 <br />
                다양한 학교 친구들과 교류할 수 있는 절호의 기회
              </div>
              <div className="div-main-instagram-kakao">
                <img
                  src={instagram}
                  alt=""
                  style={{ width: "25px" }}
                  onClick={() =>
                    (window.location.href =
                      "https://www.instagram.com/team_magnus_bjj/")
                  }
                />
                <img
                  src={kakao}
                  alt=""
                  style={{ width: "25px" }}
                  onClick={() =>
                    (window.location.href = "https://open.kakao.com/o/gKneLTHd")
                  }
                />
              </div>
              <div className="div-main-footer">
                Copyright 2022. TEAM MAGNUS All rights reserved.
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
}

export default Main;
